import { APP_API_URL } from "../constants/urls";
import { LESSON_CONTEXTS } from "../user/constants/lessons";

const API_URL = APP_API_URL;

export const API = `${API_URL}/internal_api/v1`;
export const API_V2 = `${API_URL}/internal_api/v2`;
export const API_SVC = APP_API_URL.includes("localhost")
  ? "https://my.securityjourney.dev/svc"
  : `${API_URL}/svc`;

// PAGES
export const HOME_PATH = "/";
export const LEADERBOARD_PATH = "/leaderboard";
export const USER_PROFILE_PATH = "/account/profile";
export const USER_ACCOUNT_PATH = "/account/:tab";
export const PROFILE_ACHIEVEMENTS_PATH = "/account/achievements";
export const PROFILE_CHANGE_TENANT_TAB = "/account/tenants";
export const GET_ASSIGNMENTS_PATH = "/account/assignments";
export const LESSONS_CATALOG_PATH = "/modules/catalog";
export const GET_ACHIEVEMENTS_PATH = "/achievement_wall";
export const GET_FINISHED_TOURNAMENTS_PATH = "/tournaments/finished";
export const GET_LATEST_CHANGES_PATH = "/latest_changes";
export const GET_JOURNAL_PATH = "/current_user/notes";
export const GET_JOURNEYMAP_PATH = "/journey";
export const GET_JOURNEY_PLUS_PATH = "/jp";
export const GET_DOJO_ACTIVITIES_PATH = "/dojo_activities";
export const GET_SEARCH_PATH = "/search";
export const LEARNING_JOURNAL = "/current_user/notes";
export const LEARN_PATH = "/belts/:courseId/modules/:lessonId/:step";
export const CATALOG_LEARN_PATH = "/modules/:lessonId/:step";
export const SAML_SIGN_IN_PATH = "/saml/sign_in";
export const EXPERIMENTS_PATH = "/modules/experiments";
export const EXPERIMENT_PATH = "/modules/experiments/:id";
export const EXPERIMENT_MISSION_PATH = "/missions/:missionId/experiment_missions/:id";
export const SHOW_TOURNAMENT_PATH = "/tournaments/:tournament_id/show";

export const TOURNAMENT_EXPERIMENTS_PATH =
  "/tournament_content/:exercise_id/tournament_experiments";

export const TOURNAMENT_LESSON_PATH = "/tournament_content/:exercise_id/tournament_lessons/:step";

export const SIGNOUT_PATH = "/user/sign-out";
export const ASSESSMENT_REDIRECT_PATH = "/assessment/:assignment_id";

// badge_images
export const badgeImagesPath = ({ imageId, tenantName }) =>
  `${API_URL}/badge_images/${imageId}/${tenantName}`;

//public_certificates
export const publicCertificatesPath = ({ roleEnrollmentId, tenantName }) =>
  `${API_URL}/certificates/${roleEnrollmentId}/${tenantName}`;

// lesson
export const fullLessonPath = ({ levelId, lessonId, step = "lesson", isAssignment = false }) => {
  const search = isAssignment ? `?mode=${LESSON_CONTEXTS.assignments.label}` : "";
  return `/belts/${levelId}/modules/${lessonId}/${step}${search}`;
};

// catalog lesson
export const catalogLessonPath = (lessonId, step = "lesson") => `/modules/${lessonId}/${step}`;

export const previewLessonPath = (courseId, lessonId) =>
  `/belts/${courseId}/modules/${lessonId}/preview`;

export const lessonStatusCheckPath = lessonKey => `/svc/content/int/lesson_status/${lessonKey}`;

// tournaments_v2
export const experimentPath = exerciseId =>
  `/tournament_content/${exerciseId}/tournament_experiments`;

export const lessonPath = (exerciseId, step = "lesson") =>
  `/tournament_content/${exerciseId}/tournament_lessons/${step}`;

export const getTournamentUrl = id => `/tournaments/${id}/show`;

// experiments

export const showExperimentPath = id => `/modules/experiments/${id}`;
export const showExperimentMissionPath = (missionId, experimentId) =>
  `/missions/${missionId}/experiment_missions/${experimentId}`;

// API
//achievement_wall
export const API_ACHIEVEMENTS_PATH = `${API}/achievement_wall`;
export const API_DOWNLOAD_ACHIEVEMENTS_PATH = `${API}/achievement_wall/download`;

// assessment question
export const apiAssessmentQuestionPath = id => `${API}/assessment_questions/${id}`;

// assessments before and after rating
export const apiAssessmentUpdatePath = id => `${API}/assessments/${id}`;

// assignments
export const API_ASSIGNMENTS_PATH = `${API}/assignments`;
export const API_ASSIGNMENTS_FULL_PATH = `${API}/assignments/full`;
// assignments
export const API_MICRO_ASSIGNMENTS_PATH = `${API}/micro_assignments`;
export const API_MICRO_ASSIGNMENTS_FULL_PATH = `${API}/micro_assignments/full`;

// current user
export const API_CHANGE_LOCALE = `${API}/current_user/change_locale`;
export const API_JOURNAL_PATH = `${API}/current_user/notes`;
export const API_REFRESH_PATH = `${API}/current_user/refresh`;
export const API_STOP_IMPERSONATING_PATH = `${API}/current_user/stop_impersonating`;
export const API_SWITCH_TENANT_PATH = `${API}/current_user/switch_tenant`;
export const API_USER_ACTIVITIES_PATH = `${API}/current_user/activities`;
export const API_UPDATE_CURRENT_USER_PATH = `${API}/current_user/update`;

export const apiCurrentUserPath = filter => {
  const params = filter.map(item => `filter[]=${item}`);
  return `${API}/current_user/show?${params.join("&")}`;
};
export const apiUserLessonNotes = lessonId => `${API}/current_user/lessons/${lessonId}/notes`;
export const apiUpdateUserNotePath = noteId => `${API}/current_user/notes/${noteId}`;
export const apiDeleteUserNotePath = noteId => `${API}/current_user/notes/${noteId}`;

// dojo_activities
export const API_DOJO_ACTIVITIES_PATH = `${API}/dojo_activities`;
export const API_CREATE_DOJO_ACTIVITY_PATH = `${API}/dojo_activities`;

export const apiDojoActivityPath = id => `${API}/dojo_activities/${id}`;
export const apiDojoActivityAttachmentsPath = id => `${API}/dojo_activities/${id}/attachments`;
export const apiDojoActivityImagesPath = id => `${API}/dojo_activities/${id}/images`;
export const apiDojoActivityNotesPath = id => `${API}/dojo_activities/${id}/activity_notes`;
export const apiDojoActivitySubmitPath = id => `${API}/dojo_activities/${id}/submit`;
export const apiDojoActivityUnsubmitPath = id => `${API}/dojo_activities/${id}/unsubmit`;
export const apiActivityNotePath = (activityId, id) =>
  `${API}/dojo_activities/${activityId}/activity_notes/${id}`;
export const apiDojoActivityNotesInitShow = id =>
  `${API}/dojo_activities/${id}/activity_notes/init_show`;

// enrollments
export const API_CREATE_ENROLLMENT_PATH = `${API}/enrollments`;
export const apiEnrollmentUpdatePath = id => `${API}/enrollments/${id}`;

// latest changes
export const API_LATEST_CHANGES_PATH = `${API}/latest_changes`;

// leaderboard
export const API_LEADERBOARD_PATH = `${API}/leaderboard`;
export const API_LEADERBOARD_DOWNLOAD_PATH = `${API}/leaderboard/download`;

// catalog
export const API_LESSONS_PATH = `${API}/lessons`;

// experiments
export const API_EXPERIMENTS_PATH = `${API}/experiments`;
export const API_CHARGE_EXPERIMENTS_PATH = API_EXPERIMENTS_PATH + "/charge";
export const apiNoLessonExperiment = (id, query) => `${API}/experiments/${id}${query}`;

// notifications
export const API_NOTIFICATIONS_PATH = `${API}/notifications`;
export const API_DELETE_ALL_NOTIFICATIONS_PATH = `${API}/notifications/destroy_all`;
export const apiDeleteNotificationPath = id => `${API}/notifications/${id}`;

// levels
export const apiLevelsPath = filter => {
  const params = filter.map(item => `filter[]=${item}`);
  return `${API}/levels?${params.join("&")}`;
};
export const apiUpdateCourseRolePath = id => `${API}/levels/${id}/update_user_role`;
export const apiCourseRolesPath = id => `${API}/levels/${id}/roles`;
export const apiRoleByNamePath = () => `${API}/roles/by_name`;

//missions
export const API_MISSIONS_PATH = `${API}/missions`;
export const apiMissionPath = id => `${API}/missions/${id}`;
export const API_MISSION_ENROLLMENTS_PATH = `${API}/mission_enrollments`;

// lesson
export const apiFullLessonPath = (levelId, lessonId) =>
  `${API}/levels/${levelId}/lessons/${lessonId}/full`;
export const apiPreviewLessonPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/preview`;

// lesson actions
export const apiLearnExternalAssessmentPath = ({ courseId, lessonId, assessmentId }) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/assessments/${assessmentId}/learned_external`;
export const apiReadTranscriptLessonPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/read_transcript`;
export const apiWatchedVideoAssessmentPath = ({ courseId, lessonId, assessmentId }) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/assessments/${assessmentId}/watched_video`;
export const apiTranscriptLessonPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/transcript`;
export const apiVisitTabLessonPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/visit_tab`;

// assessments
export const apiLessonAssessmentPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/assessments`;

// experiments
export const apiLessonExperimentPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/experiments`;

export const apiFinishMayhemExperimentPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/experiments/finish_mayhem`;
export const apiChangeExperimentPreferencesPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/experiments/change_experiment_preferences`;
export const apiFetchLogsPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/experiments/fetch_logs`;

export const apiNoLessonExperimentFetchLogs = (id, query) =>
  `${API}/experiments/${id}/fetch_logs${query}`;
export const apiSaveExperimentCodePath = id => `${API}/experiments/${id}/save_code`;

export const apiExperimentStepShowPath = id => `${API}/experiment_steps/${id}`;

export const apiGetExperimentVariants = id => `${API}/experiments/${id}/variants`;

// resources
export const apiLessonResourcesPath = (courseId, lessonId) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/resources`;

// micropaths
export const apiEnrollInMicroPath = microPathId => `${API}/micro_roles/${microPathId}/enroll`;

// search
export const API_SEARCH_PATH = `${API}/search`;

// tournaments_v2
export const API_TOURNAMENTS_PATH_V2 = `${API_V2}/tournaments`;
export const apiEnrollInTournamentPathV2 = id => `${API_V2}/tournaments/${id}/enroll_in_tournament`;
export const apiExperimentTournamentPathV2 = id => `${API_V2}/tournaments/${id}`;

export const apiExperimentPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/show_experiment_content`;

export const apiLessonPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/show_lesson_content`;

export const apiLessonResourcesPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/lesson_resources`;

export const apiLessonTranscriptPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/lesson_transcript`;

export const apiRestartTournamentAssessmentPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/lesson_restart_assessment`;

export const apiCreateTournamentLessonNoteV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/create_lesson_note`;

export const apiUpdateTournamentLessonNoteV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/update_lesson_note`;

export const apiRemoveTournamentLessonNoteV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/remove_lesson_note`;

export const apiStatusCheckTournamentHELessonPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/check_he_lesson_status`;

export const apiLessonNotesPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/lesson_notes`;

export const apiCheckTournamentAnswerPathV2 = id =>
  `${API_V2}/tournament_assessment_questions/${id}`;

export const apiTrackExperimentActivityPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/track_activity`;

export const apiRemoveTabPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/remove_active_tab`;

export const apiStartExerciseTournamentPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/start_exercise`;

export const apiSaveExperimentCodePathV2 = ({ exercise_id }) =>
  `${API_V2}/tournament_contents/${exercise_id}/save_code`;

export const apiSvcCheckExperimentTournamentPathV2 = exerciseId =>
  `${API_V2}/tournament_contents/${exerciseId}/check_experiment_status`;

export const apiSearchParticipantsPathV2 = id => `${API_V2}/tournaments/${id}/search_participants`;
export const API_FINISHED_TOURNAMENTS_PATH_V2 = `${API_V2}/tournaments/finished`;

// transcripts
export const apiConsumeTranscriptPath = id => `${API}/transcripts/${id}/consume`;

// user notes
export const apiLessonUserNotePath = ({ courseId, lessonId, userNoteId }) =>
  `${API}/levels/${courseId}/lessons/${lessonId}/notes/${userNoteId}`;
export const apiLessonUserNotesPath = (courseId, lessonId, format = "") =>
  `${API}/levels/${courseId}/lessons/${lessonId}/notes${format}`;

// user actions
export const API_PROFILE_QUESTIONS_PATH = `${API}/profile_questions`;

// custom notifications
export const API_CUSTOM_NOTIFICATIONS_PATH = `${API}/custom_notifications`;
export const updateCustomNotificationsPath = id => `${API}/custom_notifications/${id}`;

// users
export const API_CLOSE_ACCOUNT_PATH = `${API}/users/close_account`;
export const API_EXPORT_USER_DATA_PATH = `${API}/users/export.json`;
export const API_TRACK_LESSON_TIME_PATH = `${API}/time_tracker/lesson`;
export const API_TRACK_MISSION_EXPERIMENT_TIME_PATH = `${API}/time_tracker/mission`;

// feedback
export const API_FEEDBACK_PATH = `${API}/feedbacks`;

// tags_categories
export const TAGS_CATEGORIES_PATH = `${API}/tags_categories`;
export const CWE_TAG_DATA_PATH = "https://static-assets.securityjourney.com/cwe-data.json";

//variables
export const API_VARIABLES_PATH = `${API_URL}/variables`;

//check license
export const API_CHECK_LICENSE_PATH = `${API_URL}/check_license`;

//svc auth
export const SVC_AUTH_TOKEN = `${API_SVC}/auth/token`;

// rolesAudiences
export const ROLES_AUDIENCES_PATH = `${API_V2}/roles_audiences`;

//svc directory
export const SVC_DIRECTORY = `${API_SVC}/directory/me`;

//svc idp
export const SVC_IDP_LIST = `${API_SVC}/idp/list`;

// Knowledge-assessments-frontend routes
export const buildKafAssignment = assignmentId => {
  return `/ka/assignment/${assignmentId}`;
};
