import { useFlags } from "launchdarkly-react-client-sdk";

export const useSjExportAssignmentsFlag = () => {
  const { sjExportAssignments } = useFlags();

  return sjExportAssignments;
};

export const useEnableAssignLicense = () => {
  const { assignLicense } = useFlags();

  return assignLicense;
};

export const useEnableArchiveThroughSvcDirectory = () => {
  const { enableArchiveThroughSvcDirectory } = useFlags();

  return enableArchiveThroughSvcDirectory;
};

export const useAdminRedirectOnLogin = () => {
  const { takeAdminToAdminPageOnLogin } = useFlags();

  return takeAdminToAdminPageOnLogin;
};

export const useActiveOnlyUsersForLicense = () => {
  const { enableActiveOnlyUserCountForLicense } = useFlags();

  return enableActiveOnlyUserCountForLicense;
};

export const useUpdateMyPathsInAdminPage = () => {
  const { updateMyPathsInAdminPage } = useFlags();

  return updateMyPathsInAdminPage;
};

export const useNewDefaultPathCategory = () => {
  const { newDefaultPathCategory } = useFlags();

  return newDefaultPathCategory;
};

export const useHideDashboardReports = () => {
  const { hideDashboardReports } = useFlags();

  return hideDashboardReports;
};

export const useEditTournamentsAudiencesEnabled = () => {
  const { editTournamentsAudiencesEnabled } = useFlags();

  return editTournamentsAudiencesEnabled;
};

export const useDeductPointsInTournamentMode = () => {
  const { deductPointsInTournamentMode } = useFlags();

  return deductPointsInTournamentMode;
};

export const useNotStartedStatusAssignmentReport = () => {
  const { notStartedStatusAssignmentReport } = useFlags();

  return notStartedStatusAssignmentReport;
};

export const useSjBreakfixBrowserButtons = () => {
  const { sjBreakfixBrowserButtons } = useFlags();
  return sjBreakfixBrowserButtons;
};

export const useTimeTracker = () => {
  const { timetracker } = useFlags();

  return timetracker;
};

export const useHideMissions = () => {
  const { hideMissions } = useFlags();

  return hideMissions;
};

export const useHideMicroPaths = () => {
  const { hideMicroPaths } = useFlags();

  return hideMicroPaths;
};

export const useSjKnowledgeAssessments = () => {
  const { sjKnowledgeAssessments } = useFlags();

  return sjKnowledgeAssessments;
};

export const useNoImmediateRefresh = () => {
  const { noImmediateRefresh } = useFlags();

  return noImmediateRefresh;
};

export const useCollapsibleMenuItems = () => {
  const { collapsibleMenuItems } = useFlags();

  return collapsibleMenuItems;
};

// When removed, clean up with the following:
// delete src/admin/containers/Levels.js
// remove the "Levels" menu item in src/admin/helpers/sidebar.js
export const useThemesShowInSettingsPage = () => {
  const { themesShowInSettingsPage } = useFlags();

  return themesShowInSettingsPage;
};

export const useTempTimeoutFix = () => {
  const { tempTimeoutFix } = useFlags();

  return tempTimeoutFix;
};

export const useUpdatedIdpUi = () => {
  const { enableIdpAdminUiChanges } = useFlags();

  return enableIdpAdminUiChanges;
};

export const useSvcIdpRedirect = () => {
  const { useServiceIdpForRedirect } = useFlags();

  return useServiceIdpForRedirect;
};

export const useNewUsersPage = () => {
  const { newUsersPage } = useFlags();

  return newUsersPage;
};

export const useEnableNavTour = () => {
  const { enableNavbarTour } = useFlags();

  return enableNavbarTour;
};

export const useComplianceReport = () => {
  const { useComplianceReport } = useFlags();

  return useComplianceReport;
};

export const useSafeAttachmentLoading = () => {
  const { safeAttachmentLoading } = useFlags();

  return safeAttachmentLoading;
};

export const useAllowAssessmentDemos = () => {
  const { allowAssessmentDemos } = useFlags();

  return allowAssessmentDemos;
};

export const useStrictAssignmentModal = () => {
  const { strictAssignmentModal } = useFlags();

  return strictAssignmentModal;
};

export const useStrictKnowledgeAssessment = () => {
  const { strictKnowledgeAssessment } = useFlags();

  return strictKnowledgeAssessment;
};

export const useKaProgressBars = () => {
  const { kaProgressBars } = useFlags();

  return kaProgressBars;
};
